<template>
  <b-row>
    <b-col cols="6" offset="3">
      <b-card-group
        deck
        class="mb-0"
      >

        <!-- Card 1 : Nouveau DEC -->
        <b-card>
            <div class="d-flex flex-row justify-content-center align-items-center" style="height: 100%;">
                <b-card-text class="text-center">
                    <b-container>
                        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" to="/decs/add">
                            <feather-icon class="text-primary mb-2" icon="PlusSquareIcon" size="70"/>
                            <h3 class="text-primary">Créer Nouveau DEC</h3>
                        </b-button>
                    </b-container>
                </b-card-text>
            </div>
        </b-card>

        <!-- Card 2 : Liste DECs -->
        <b-card>
            <div class="d-flex flex-row justify-content-center align-items-center" style="height: 100%;">
                <b-card-text class="text-center">
                    <b-container>
                        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" to="/decs/list">
                            <feather-icon class="text-primary mb-2" icon="ListIcon" size="70"/>
                            <h3 class="text-primary">Liste des DECs</h3>
                        </b-button>
                    </b-container>
                </b-card-text>
            </div>
        </b-card>

        <!-- Card 2 : Liste Affaires -->
        <b-card>
            <div class="d-flex flex-row justify-content-center align-items-center" style="height: 100%;">
                <b-card-text class="text-center">
                    <b-container>
                        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" to="/affaires/list">
                            <feather-icon class="text-primary mb-2" icon="ListIcon" size="70"/>
                            <h3 class="text-primary">Liste des Affaires</h3>
                        </b-button>
                    </b-container>
                </b-card-text>
            </div>
        </b-card>
      </b-card-group>
    </b-col>
  </b-row>
</template>

<script>

import {
  BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody, BContainer, BButton
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple},
    
    components: {
        BRow,
        BCol,
        BCardGroup,
        BCard,
        BCardText,
        BCardTitle,
        BCardFooter,
        BCardBody,
        BContainer,
        BButton,
    },
}
</script>
